export default {
  copy_hunt_enabled: false,
  // sku_lineup: ["f8cqOBSvwpTz6iWg7dHM"]
  sku_lineup: [
    "PKkeYQxo9YMfc8QoxVzX",
    "urGHGWKZ2g6uwDb40e9i",
    "HIbYa0uxxBKsGVMWayj7",
    "euRSdHllWLgF3J22TAYY"
  ],
  download_tab: "on",
  manager_feed_feature: "on"
};
